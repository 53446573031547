export const Starting_Instructions = [
  "1. Go back to the box.",
  "2. Get the smart plug. For now, plug it into any power socket. This helps the other sensors connect faster.",
  "3. If you haven't already, insert batteries into the remaining sensors.",
  "4. Only when all batteries are in should you click 'Test connection'.",
];

export const Locations_Required = [
  "All the sensors are online.",
  "Next, select the location for each sensor below.",
];

const doNotCloseApp = "Do not close the app while you complete this step.";

export const Not_All_Sensors_Online = [
  "Not all the sensors came online. To fix, click on the unconnected sensor and then on 'Need help?'.",
  doNotCloseApp,
];
