import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { getReportDateRange } from "@intelligentlilli/api-layer";
import useCreateReportSteps from "../../Services/Hooks/useCreateReportSteps";
import {
  CompleteIcon,
  NighttimeIcon,
  PersonIcon,
  SustenanceIcon,
  ToiletIcon,
} from "../../Styles/Icons/DesignSystem";
// Components
import NewStyleModal from "../NewStyleModal";
import Stepper from "../Stepper";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Buttons from "./Buttons";
import CreatingReportSpinner from "./CreatingReportSpinner";
import ErrorCreatingReport from "./ErrorCreatingReport";
import {
  differenceInDays,
  differenceInYears,
  addDays,
  endOfDay,
  isBefore,
} from "date-fns";

function CreateNewReportModal({
  skipSUSelection,
  serviceUsers,
  reportTypes,
  showAnimation,
  setShowAnimation,
  createReport,
}) {
  // Redux state
  const server = useSelector((state) => state.session.server);
  const user = useSelector((state) => state.user);

  const getReportIcon = (reportType) => {
    switch (reportType) {
      case "night_time_report":
        return NighttimeIcon;
      case "care_assessment_report":
        return CompleteIcon;
      case "time_outside_report":
        return PersonIcon;
      case "eating_and_drinking_report":
        return SustenanceIcon;
      case "bathroom_report":
        return ToiletIcon;
      default:
        return CompleteIcon;
    }
  };

  // Augment the report types with the icon and available properties
  const augmentedReportTypes = reportTypes.map((type) => ({
    ...type,
    icon: getReportIcon(type.id),
    available: true,
  }));

  // Local state
  const [reportType, setReportType] = useState(augmentedReportTypes[0]);
  const [behaviourReportSelections, setBehaviourReportSelections] = useState([
    { name: "Eating & drinking", key: "sustenance", selected: true },
    { name: "Movement", key: "movement", selected: true },
    { name: "Time outside", key: "independence", selected: true },
    { name: "Night-time activity", key: "nighttime_activity", selected: true },
  ]);
  const [creatingReport, setCreatingReport] = useState(false); // This is used to show the loading spinner when the report is being created
  const [errorCreatingReport, setErrorCreatingReport] = useState(false); // This is used to show the loading spinner when the report is being created
  const [availableDateRange, setAvailableDateRange] = useState(null); // ["2023-01-01", "2023-01-01"]
  const [dateRange, setDateRange] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    startDateYetToBeSelected: true,
    endDateYetToBeSelected: true,
  });
  const [serviceUser, setServiceUser] = useState(
    skipSUSelection
      ? {
          name: serviceUsers[0]?.userstring,
          value: serviceUsers[0]?.id,
          label: serviceUsers[0]?.userstring,
        }
      : null
  );
  const [showValidationError, setShowValidationError] = useState(false);
  const [dateRangeError, setDateRangeError] = useState();

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      if (isBefore(dateRange.endDate, dateRange.startDate)) {
        setDateRangeError("End date must be after start date");
      } else if (
        reportType.id === "general-report" &&
        differenceInDays(dateRange.endDate, dateRange.startDate) > 31
      ) {
        setDateRangeError(
          "Behaviour reports can have a maximum range of 31 days"
        );
      } else {
        setDateRangeError(null);
      }
    }
  }, [dateRange.startDate, dateRange.endDate, reportType.id]);

  const serviceUserData = serviceUsers.find(
    (SU) => SU.id === serviceUser?.value
  );

  // Custom hook to manage the steps
  const { steps, currentStepIndex, setCurrentStepIndex } = useCreateReportSteps(
    serviceUser?.label,
    reportType.title,
    skipSUSelection
  );

  // When the user selects a service user and report type, we need to check if there is a date range available for the report type
  useEffect(() => {
    const getAvailableDateRange = async (reportType, serviceUserId) => {
      setAvailableDateRange("loading");
      try {
        const dateRange = await getReportDateRange(
          server,
          serviceUserId,
          reportType,
          "web"
        );
        if (dateRange.ok && dateRange.body?.availableTimeRanges?.length > 0) {
          setAvailableDateRange(dateRange.body);
          // Set the date range to the first available date
          setDateRange((prev) => ({
            ...prev,
            startDate: new Date(dateRange.body.availableTimeRanges[0].endDate),
            endDate: new Date(dateRange.body.availableTimeRanges[0].endDate),
          }));
        } else {
          setAvailableDateRange(null);
        }
      } catch (e) {
        setAvailableDateRange(null);
        console.log("Error getting date range:", e);
      }
    };
    if (serviceUser?.value && reportType.id) {
      void getAvailableDateRange(reportType.id, serviceUser.value);
    }
  }, [serviceUser?.value, reportType.id, server]);

  const callCreateReport = () => {
    // Show an error if the user has not selected a date range
    if (
      dateRange.startDateYetToBeSelected ||
      dateRange.endDateYetToBeSelected
    ) {
      setShowValidationError(true);
      return;
    }
    if (dateRangeError) {
      return;
    }
    const V1StartDate = addDays(dateRange.startDate, 1);
    const V1EndDate = addDays(dateRange.endDate, 1);
    const V2StartDate = dateRange.startDate;
    const V2EndDate = endOfDay(new Date(dateRange.endDate));
    const startDate =
      reportType.id === "general-report" ? V1StartDate : V2StartDate;
    const endDate = reportType.id === "general-report" ? V1EndDate : V2EndDate;

    createReport(
      {
        serviceUserId: `${serviceUser.value}`,
        reportType: reportType.id,
        reportStartDate: startDate.toISOString().split(".")[0] + "Z",
        reportEndDate: endDate.toISOString().split(".")[0] + "Z",
        entityProperties: {
          forenames: serviceUserData?.serviceUserProperties?.forenames,
          surname: serviceUserData?.serviceUserProperties?.surname,
          fullName: serviceUserData?.userstring,
          dateOfBirth: serviceUserData?.serviceUserProperties?.dateOfBirth,
          age: serviceUserData?.serviceUserProperties?.dateOfBirth
            ? `${differenceInYears(
                new Date(),
                new Date(serviceUserData.serviceUserProperties.dateOfBirth)
              )}`
            : "Unknown",
          careId: serviceUserData?.serviceUserProperties?.careId,
          phoneNumber: serviceUserData?.serviceUserProperties?.phoneNumber,
          createdBy: `${user?.forenames} ${user?.surname}`,
        },
      },
      {
        reportName: reportType.title,
        options: behaviourReportSelections.map((option) => option.key),
      },
      setCreatingReport,
      setErrorCreatingReport
    );
    !dateRange.startDateYetToBeSelected &&
      !dateRange.endDateYetToBeSelected &&
      setCreatingReport(true);
  };

  return (
    <NewStyleModal
      showCloseIcon={true}
      hide={() => {
        setShowAnimation(false);
      }}
      showAnimation={showAnimation}
      title={!creatingReport && !errorCreatingReport && "Create a new report"}
      size="large"
    >
      {!creatingReport && !errorCreatingReport && (
        <>
          <div className={styles.stepperContainer}>
            <Stepper steps={steps} currentStepIndex={currentStepIndex} />
          </div>
          {currentStepIndex === 0 && (
            <Step1
              serviceUser={serviceUser}
              setServiceUser={setServiceUser}
              showValidationError={showValidationError}
              serviceUsers={serviceUsers}
            />
          )}
          {currentStepIndex === 1 && (
            <Step2
              setReportType={setReportType}
              reportTypes={augmentedReportTypes}
              reportType={reportType}
              behaviourReportSelections={behaviourReportSelections}
              setBehaviourReportSelections={setBehaviourReportSelections}
              serviceUser={serviceUser}
            />
          )}
          {currentStepIndex === 2 && (
            <Step3
              availableDateRange={availableDateRange}
              dateRange={dateRange}
              setDateRange={setDateRange}
              showValidationError={showValidationError}
              dateRangeError={dateRangeError}
            />
          )}

          <div className={styles.bottom}>
            <p>{`Step ${currentStepIndex + 1} of ${steps.length}`}</p>
            {currentStepIndex === 0 && (
              <Buttons
                firstStep
                setCurrentStepIndex={setCurrentStepIndex}
                onClick={() => {
                  serviceUser && setCurrentStepIndex((prev) => prev + 1);
                  !serviceUser && setShowValidationError(true);
                }}
              />
            )}
            {currentStepIndex === 1 && (
              <Buttons
                setCurrentStepIndex={setCurrentStepIndex}
                onClick={() => {
                  setCurrentStepIndex((prev) => prev + 1);
                }}
              />
            )}
            {currentStepIndex === 2 && (
              <Buttons
                setCurrentStepIndex={setCurrentStepIndex}
                nextButtonText="Create report"
                onClick={callCreateReport}
              />
            )}
          </div>
        </>
      )}
      {errorCreatingReport && (
        <ErrorCreatingReport
          onClickCancel={() => {
            setShowAnimation(false);
          }}
          onClickTryAgain={() => {
            setErrorCreatingReport(false);
            callCreateReport();
          }}
        />
      )}
      {creatingReport && <CreatingReportSpinner />}
    </NewStyleModal>
  );
}

export default CreateNewReportModal;
