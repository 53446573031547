import { getMissedMealContext } from "../getMissedMealContext";
import { hasSustenanceData } from "../hasSustenanceData";

export const sustenanceDayMissedMeals = (sustenanceData, onClick) => {
  const haveData = hasSustenanceData(sustenanceData?.[0]?.dailyData);
  const contextWhenMissedMeals = getMissedMealContext(sustenanceData?.[0]);

  const missedMeals = sustenanceData?.[0]?.sentences?.length;

  if (!haveData) {
    return {
      offline: true,
      title: "Meals",
      icon: "OFFLINE",
      context: "No data available for this insight",
      onClick: onClick,
    };
  }
  return {
    title: "Meals",
    headline: `${missedMeals} missed`,
    icon: "SUSTENANCE",
    context: contextWhenMissedMeals,
    onClick: onClick,
  };
};

export const sustenanceDayDaytimeEvents = (sustenanceData, onClick) => {
  const haveData = hasSustenanceData(sustenanceData?.[0]?.dailyData);

  const kettleCount = sustenanceData?.[0]?.hourlyData
    ?.filter((hour) => hour.hour >= 6 && hour.hour < 22)
    .reduce((acc, curr) => acc + curr.smartplugCount, 0);

  const fridgeCount = sustenanceData?.[0]?.hourlyData
    ?.filter((hour) => hour.hour >= 6 && hour.hour < 22)
    .reduce((acc, curr) => acc + curr.fridgeCount, 0);

  if (!haveData) {
    return {
      offline: true,
      title: "Eating & Drinking during the day",
      icon: "OFFLINE",
      context: "No data available for this insight",
      onClick: onClick,
    };
  }
  return {
    title: "Eating & Drinking during the day",
    icon: "SUSTENANCE",
    onClick: onClick,
    children: {
      type: "SPLIT_INSIGHT",
      left: { context: "Smart plug event", number: kettleCount },
      right: { context: "Door open", number: fridgeCount },
    },
  };
};

export const sustenanceDayNighttimeEvents = (
  sustenanceData,
  sustenanceDataPrevious,
  onClick
) => {
  const haveData = hasSustenanceData(sustenanceData?.[0]?.dailyData);

  // if the SU has just been installed, there is no data for sustenanceDataPrevious(Day)
  // the hourly data for sustenanceData for pre-installation is also defined as null
  const sumCounts = (data, condition, key) => {
    return (
      data?.[0]?.hourlyData
        ?.filter(condition)
        ?.reduce((acc, curr) => acc + (curr[key] || 0), 0) || 0
    );
  };

  const isEarlyMorning = (hour) => hour.hour < 6;
  const isLateNight = (hour) => hour.hour >= 22;

  const kettleCount =
    sumCounts(sustenanceData, isEarlyMorning, "smartplugCount") +
    sumCounts(sustenanceDataPrevious, isLateNight, "smartplugCount");

  const fridgeCount =
    sumCounts(sustenanceData, isEarlyMorning, "fridgeCount") +
    sumCounts(sustenanceDataPrevious, isLateNight, "fridgeCount");

  if (!haveData) {
    return {
      offline: true,
      title: "Eating & Drinking at night",
      icon: "OFFLINE",
      context: "No data available for this insight",
      onClick: onClick,
    };
  }
  return {
    title: "Eating & Drinking at night",
    icon: "SUSTENANCE",
    onClick: onClick,
    children: {
      type: "SPLIT_INSIGHT",
      left: { context: "Smart plug event", number: kettleCount },
      right: { context: "Door open", number: fridgeCount },
    },
  };
};
