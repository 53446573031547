import { useSelector } from "react-redux";
import {
  getIsUserJustAnInstaller,
  getUserHasInstallerRole,
  getUserIsManagerOrAdmin,
  getIsUserStaff,
  getUserRoleAsString,
} from "../Utils";

export const useGetUserRole = () => {
  const user = useSelector((state) => state.user);
  const userRoles = user?.roles;
  const userIsStaff = getIsUserStaff(userRoles);
  const userIsFriendOrFamily = userRoles?.includes("friend-or-family");
  const userIsManagerOrAdmin = getUserIsManagerOrAdmin(userRoles);
  const userHasInstallerRole = getUserHasInstallerRole(userRoles);
  const userIsJustAnInstaller = getIsUserJustAnInstaller(userRoles);
  const userRoleAsAString = getUserRoleAsString(userRoles);

  return {
    userRoleAsAString,
    userIsFriendOrFamily,
    userIsStaff,
    userIsManagerOrAdmin,
    userHasInstallerRole,
    userIsJustAnInstaller,
  };
};
