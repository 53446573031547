import { format } from "date-fns";
import { getHoursAndMinutesAsString } from "../getHoursAndMinutesAsString";

export const nightTimeDayExpectedDuration = (
  movementData,
  serviceUserString,
  onClick,
  noMovementData
) => {
  // if the user is just installed, there is no data for the previous night and durationMinutes?.nightTime comes back as undefined/null

  const userJustInstalled =
    movementData?.durationMinutes?.nightTime === undefined ||
    movementData?.durationMinutes?.nightTime === null;

  if (noMovementData || userJustInstalled) {
    return {
      offline: true,
      title: "Night-time activity",
      icon: "OFFLINE",
      context: "No data available for this insight",
      onClick: onClick,
    };
  }
  const nightTimeDuration = movementData?.durationMinutes?.nightTime;
  const nightTimeDurationString =
    getHoursAndMinutesAsString(nightTimeDuration, false) || "";
  const nightTimeMin = movementData?.expectedDurationMinutes?.nighttimeMin;
  const nightTimeMinString = getHoursAndMinutesAsString(nightTimeMin, false);
  const nightTimeMax = movementData?.expectedDurationMinutes?.nighttimeMax;
  const nightTimeMaxString = getHoursAndMinutesAsString(nightTimeMax, false);

  const totalMovementsSentence = [
    {
      text: `${
        serviceUserString || "This person"
      } moved around the home for ${nightTimeDurationString} last night. `,
      type: "normal",
    },
  ];

  const isBelowMax = nightTimeDuration <= nightTimeMax;
  const isAboveMin = nightTimeDuration >= nightTimeMin;
  const isWithinRange = isBelowMax && isAboveMin;

  const isModelReady =
    movementData?.night?.insights?.[movementData?.night?.insights?.length - 1]
      ?.modelReady;

  const riskScore = !isModelReady ? 0 : isWithinRange ? 0 : 1;

  const atRiskSentence = [
    { text: "This is", type: "normal" },
    {
      text: !isBelowMax ? " more than usual. " : " less than usual. ",
      type: "bold",
    },
  ];

  if (riskScore === 1 && isModelReady) {
    totalMovementsSentence.push(...atRiskSentence);
  }

  const insightContextLearningPeriod = [
    ...totalMovementsSentence,
    {
      text: "Lilli is still learning their expected behaviour.",
      type: "normal",
    },
  ];

  const insightContext =
    !nightTimeMin || !nightTimeMax
      ? totalMovementsSentence
      : [
          ...totalMovementsSentence,
          {
            text: `Over a full night, Lilli expects ${
              serviceUserString || "this person"
            } to move for a ${!isAboveMin ? "minimum" : "maximum"} of ${
              !isAboveMin ? nightTimeMinString : nightTimeMaxString
            }.`,
            type: "normal",
          },
        ];

  return {
    title: "Night-time activity",
    context: !isModelReady ? insightContextLearningPeriod : insightContext,
    riskLevel: riskScore,
    icon: "SLEEP",
    onClick: onClick,
    children: {
      type: "MOVEMENT_DONUT",
      totalMovements: movementData?.durationMinutes?.nightTime,
      totalMovementsString: nightTimeDurationString,
      expectedMovements: !isModelReady
        ? null
        : !isAboveMin
          ? nightTimeMin
          : nightTimeMax,
      expectedMovementsString: !isModelReady
        ? null
        : `${!isAboveMin ? nightTimeMinString : nightTimeMaxString} expected`,
    },
  };
};

export const nightTimeDayLastMovementHour = (movementData) => {
  const lastMovementHour =
    movementData?.day?.lastEvent?.[1] &&
    format(new Date(movementData?.day?.lastEvent[1]), "haaa");
  return {
    title: "Wind down",
    headline: `${lastMovementHour || "No events"}`,
    context: lastMovementHour
      ? "was yesterday's last activity (6am - 10pm)"
      : null,
    icon: "SLEEP",
  };
};
